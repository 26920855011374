import antdBR from "antd/lib/locale-provider/pt_BR";
import enMessages from "../locales/pt_BR.json";

const PtLang = {
  messages: {
    ...enMessages
  },
  antd: antdBR,
  locale: 'pt-BR',
};
export default PtLang;
