import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./ProfilePage'))}/>
      <Route path={`${match.url}dreams/store`} component={asyncComponent(() => import('./DreamStorePage'))}/>
      <Route path={`${match.url}dreams/:id`} component={asyncComponent(() => import('./DreamUpdatePage'))}/>
      <Route path={`${match.url}dreams`} component={asyncComponent(() => import('./DreamPage'))}/>
      <Route path={`${match.url}dream_themes/store`} component={asyncComponent(() => import('./DreamThemeStorePage'))}/>
      <Route path={`${match.url}dream_themes/:id`} component={asyncComponent(() => import('./DreamThemeUpdatePage'))}/>
      <Route path={`${match.url}dream_themes`} component={asyncComponent(() => import('./DreamThemePage'))}/>
    </Switch>
  </div>
);

export default App;
