import axios from 'axios';
import {notification} from 'antd'
import _ from "lodash";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
    'x-access-token': localStorage.getItem('token')
  }
});
// Add a request interceptor
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token.replaceAll("\"","");
    config.headers["x-access-token"] =  token.replaceAll("\"","");
  }
  const url = localStorage.getItem("url");
  if(url) {
    config.baseURL = url
  }

  config.headers["Content-Type"] = "application/json";

  return config;
}, function (error) {
  return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(function (response) {
  if (response.config.method === "post" && response.status === 201) {
    notification.success({message: "Adicionado!"});
  }
  if (response.config.method === "put" && response.status === 200) {
    notification.success({message: "Atualizado!"});
  }

  return response;
}, function (error) {
  const response = error.response;
  console.error("Erro interceptado:", response);
  console.error(response.status, response.statusText);
  console.log("Mensagem:", response.data.error.message);
  console.log("Erros:", response.data.error.message.errors);

  // let mainMessage = response.statusText;
  // if (response.data.error.message) {
  //   mainMessage = response.data.error.message;
  // }

  if (response.status === 422) {

    const body = [];
    _.forEach(response.data.error.errors, (fieldErrors) => {
        _.forEach(fieldErrors, (error) => {
          body.push(error);
        });
      }
    );
    notification.error({message: response?.data?.error?.message, description: body});


  }

  if (response.status === 403 || response.status === 401 || response.status === 404) {
    notification.error({message: response.data.error.message});
  }

  if (response.status === 500) {
    notification.error({message: 'Error no servidor'});
  }
  return Promise.reject(error);
});

export default api;
