import {MET_SET_ME} from "../../constants/ActionTypes";

const INIT_STATE = {};

const MeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MET_SET_ME: {
      console.log(action.payload, 'djd')
      return action?.payload
    }
    default:
      return state;
  }
}

export default MeReducer;
