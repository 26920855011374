import React from "react";
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, {history} from './appRedux/store';
import {PersistGate} from 'redux-persist/integration/react'
import "./firebase/firebase";
import App from "./containers/App/index";

const store = configureStore(/* provide initial state if any */);

const NextApp = () =>
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App}/>
        </Switch>
      </ConnectedRouter>
    </PersistGate>
  </Provider>;


export default NextApp;
